// @ts-check
import React, { useContext, useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';

// core
import {
  AppContext,
  appErrors,
  Breadcrumb,
  CAMPAIGN_STATUS,
  CheckBoxLabel,
  ClipboardCopy,
  CsvDataTable,
  formatQueryCampaignMessage,
  OPEN_MODE,
  PAGE_STEP,
  PageTitle,
  processQuery,
  QueryFilterBuilder,
  QueryFilterPreview,
  resolveRouteWithId,
  routerUrl,
  scrollTopPage,
  useScrollTop,
  wsResponseType,
} from 'core';
import { previewTableConfig } from 'core/components/Tables/configs';

// state
import {
  useStateCampaign,
  useStateExportFilters,
  useStateFilters,
} from 'core/hooks/use-state-campaign';
import { useAppDispatch, useAppSelector } from 'core/store';
import {
  exportCampaignAsync,
  publishCampaignAsync,
  resetAlertMessages,
  resetCampaignPreview,
  setCalculatedMatchSummaryWebsocket,
  setCampaignData,
  setCampaignPreviewLoading,
  setCampaignPreviewWebsocket,
  setExportInProgress,
  setInfoMessage,
  setPageLoading,
  setPageSummary,
  setSelectedRoute,
  setStatementPreviewAsync,
  updateCampaignAsync,
} from 'core/store/slices';

// components
import { DetailList } from './components/DetailList';
import { ExportFileSummary } from './components/ExportFileSummary';

function CampaignDetails() {
  const { sendMessage, stopSendMessage } = useContext(AppContext);
  const dispatch = useAppDispatch();
  const {
    campaignData,
    exportFilter,
    queryBuilder,
    campaignDownload,
    campaignPreview,
  } = useAppSelector((state) => state);

  const {
    loading: campaignLoading,
    campaign,
    openMode,
    statementPreview,
  } = campaignData;
  const { calculatedMatchSummary } = campaign;
  const { exportFilters } = exportFilter;
  const { filters } = queryBuilder;
  const { exportInProgress, downloadInProgress, downloadEnd } =
    campaignDownload;

  const { loading: previewDataLoading, previewData } = campaignPreview;

  console.log('Preview data: ', previewData);

  const latestStatement = campaign?.details?.statements?.length
    ? campaign.details.statements.reduce((latest, statement) => {
        if (!latest) return statement;
        return new Date(statement.started_at) > new Date(latest.started_at)
          ? statement
          : latest;
      }, null)
    : null;

  const hasPreviewData = previewData?.records?.length > 0;
  const hasStatementPreview = statementPreview?.preview?.records?.length > 0;

  const columns = hasPreviewData
    ? previewData.columns
    : hasStatementPreview
    ? statementPreview.preview.columns
    : [];

  const records = hasPreviewData
    ? previewData.records
    : hasStatementPreview
    ? statementPreview.preview.records
    : [];

  const totalRecordCount =
    previewData?.totalRecordCount && previewData.totalRecordCount > 0
      ? previewData.totalRecordCount
      : latestStatement?.record_count && latestStatement.record_count > 0
      ? latestStatement.record_count
      : 0;

  const history = useHistory();

  useStateExportFilters();
  useStateFilters();
  useStateCampaign();
  useScrollTop();

  useEffect(() => {
    dispatch(setSelectedRoute(routerUrl.CAMPAIGN_DETAILS));
    dispatch(setPageSummary(null));
    dispatch(setCampaignData({ openMode: OPEN_MODE.VIEW }));
  }, [dispatch]);

  useEffect(() => {
    if (exportInProgress) {
      dispatch(setInfoMessage('The export of the campaign is in progress'));
      scrollTopPage();
    }
  }, [dispatch, exportInProgress]);

  useEffect(() => {
    if (downloadInProgress) {
      dispatch(setInfoMessage('The download of the campaign is in progress'));
    } else if (downloadEnd) {
      dispatch(setInfoMessage(''));
    }
  }, [dispatch, downloadEnd, downloadInProgress]);

  useEffect(() => {
    return () => {
      dispatch(resetAlertMessages());
    };
  }, [dispatch]);

  useEffect(() => {
    // this function is triggered when the Page component is unmounted
    // in this case it stops the websocket when it changes page
    return () => {
      stopSendMessage();
    };
  }, [stopSendMessage]);

  useEffect(() => {
    async function setStatementPreview() {
      dispatch(
        setStatementPreviewAsync({
          statementId: latestStatement.statement_id,
        }),
      );
    }

    if (latestStatement?.statement_id) {
      setStatementPreview();
    }
  }, [dispatch, latestStatement]);

  // useEffect(() => {
  //   if (
  //     !campaign?.campaignId ||
  //     !campaign?.logic ||
  //     campaign?.logic === previewData.queryLogic
  //   )
  //     return;

  //   dispatch(setCampaignPreviewLoading(true));

  //   const message = formatQueryCampaignMessage(campaign);

  //   sendMessage({
  //     handleError: true,
  //     message,
  //     onMessageCallback: (evt) => {
  //       dispatch(
  //         setCampaignPreviewWebsocket({
  //           wsData: evt.data,
  //           queryLogic: campaign?.logic,
  //         }),
  //       );
  //       dispatch(setCalculatedMatchSummaryWebsocket({ wsData: evt.data }));
  //     },
  //   });
  // }, [
  //   campaign,
  //   dispatch,
  //   previewData.queryLogic,
  //   sendMessage,
  //   stopSendMessage,
  // ]);

  const refreshCampaign = () => {
    if (!campaign?.campaignId || !campaign?.logic) return;

    dispatch(setCampaignPreviewLoading(true));

    const message = formatQueryCampaignMessage(campaign);

    sendMessage({
      handleError: true,
      message,
      onMessageCallback: (evt) => {
        console.log('refreshCampaign: ', evt.data);
        dispatch(
          setCampaignPreviewWebsocket({
            wsData: evt.data,
            queryLogic: campaign?.logic,
          }),
        );
        dispatch(setCalculatedMatchSummaryWebsocket({ wsData: evt.data }));
      },
    });
  };

  const computeQueryLogic = useMemo(
    () => processQuery(campaign.logic),
    [campaign.logic],
  );

  const downloadCampaign = (statementId = '', type = '') => {
    let downloadFilename = `${campaign.campaignName.replace(
      / /g,
      '_',
    )}_${new Date().toISOString().replace(/[:.]/g, '-')}`;

    downloadFilename += `${type === 'audit' ? '_audit' : ''}.csv`;

    dispatch(exportCampaignAsync({ statementId, type, downloadFilename }));
  };

  const exportList = () => {
    if (!campaign.logic) return;

    dispatch(setPageLoading(true));
    dispatch(setExportInProgress(true));

    const message = formatQueryCampaignMessage(campaign);
    sendMessage({
      message,
      handleError: true,
      queryFailedError: appErrors.queryFailedError,
      internalServerError: appErrors.internalServerError,
      onMessageCallback: (evt) => {
        if (evt.data.detail_type === wsResponseType.QUERY_RESULTS) {
          downloadCampaign(evt.data.detail.statement_id);
        }
      },
    });
  };

  //TODO: Refactor. One function to handle both exportAuditFile and exportList.
  const exportAuditFile = () => {
    if (!campaign.logic) return;

    dispatch(setPageLoading(true));
    dispatch(setExportInProgress(true));

    const message = formatQueryCampaignMessage(campaign);
    sendMessage({
      message,
      handleError: true,
      queryFailedError: appErrors.queryFailedError,
      internalServerError: appErrors.internalServerError,
      onMessageCallback: (evt) => {
        if (evt.data.detail_type === wsResponseType.QUERY_RESULTS) {
          downloadCampaign(evt.data.detail.statement_id, 'audit');
        }
      },
    });
  };

  const publishCampaign = () => {
    dispatch(
      publishCampaignAsync({
        published: true,
        campaignId: campaign.campaignId,
      }),
    );
  };

  /**
   * @param {{
   *  checked: boolean
   *  field: 'list' | 'logic'
   * }} param
   */
  const shareToggle = ({ checked, field }) => {
    const update =
      field === 'list' ? { isListShared: checked } : { isLogicShared: checked };
    const campaignStatus =
      field === 'list'
        ? CAMPAIGN_STATUS.UPDATED_LIST_SHARED
        : CAMPAIGN_STATUS.UPDATED_LOGIC_SHARED;

    dispatch(
      updateCampaignAsync({
        status: campaignStatus,
        campaign: {
          ...campaign,
          ...update,
        },
        // TODO:TBD resume this line show banner message when toggle set to true
        // successMessage: checked
        //   ? `Campaign ${field} has been shared. ` +
        //     `It can now be viewed and exported publicly.`
        //   : '',
      }),
    );
  };

  /**
   * @param {boolean} checked
   */
  const listSharedToggle = (checked) => {
    shareToggle({
      checked,
      field: 'list',
    });
  };

  /**
   * @param {boolean} checked
   */
  const logicSharedToggle = (checked) => {
    shareToggle({
      checked,
      field: 'logic',
    });
  };

  const detailClick = () => {
    dispatch(resetCampaignPreview());
    dispatch(
      setCampaignData({
        openMode: OPEN_MODE.UPDATE,
      }),
    );

    const url = campaign.published
      ? '#'
      : resolveRouteWithId(routerUrl.CAMPAIGN_UPDATE_DATA, campaign.campaignId);

    history.push(url);
  };

  return (
    <section>
      <header>
        <PageTitle title="Campaign details" />
        <Breadcrumb
          currentPage={PAGE_STEP.CAMPAIGN_DETAILS}
          openMode={openMode}
        />
      </header>
      <section>
        <div className="d-flex justify-content-between">
          <div>
            <h3>{campaign.campaignName}</h3>
          </div>
          <div className="my-auto">
            <div>
              <strong>Campaign ID:</strong>
            </div>
            <div>{campaign.campaignId}</div>
            <div>
              <ClipboardCopy textToCopy={campaign.campaignId} />
            </div>
          </div>
        </div>
        <p>{campaign.campaignDescription}</p>
      </section>
      <section>
        <div className="row row-cols-2">
          <div className="col-6">
            <CheckBoxLabel
              title="Campaign Purposes"
              items={campaign.campaignPurpose.purposes}
              otherItem={campaign.campaignPurpose.other}
            />
          </div>
          <div className="col-6">
            <CheckBoxLabel
              title="Outreach Channels"
              items={campaign.outreachChannel.channels}
              otherItem={campaign.outreachChannel.other}
            />
          </div>
        </div>
      </section>
      <section>
        <DetailList
          loading={campaignLoading}
          campaign={campaign}
          latestStatement={latestStatement}
          exportFilters={exportFilters}
          exportInProgress={exportInProgress}
          isPreviewDataLoading={previewDataLoading}
          totalRecordCount={previewData.totalRecordCount}
          refreshCampaign={refreshCampaign}
          onListSharedToggle={listSharedToggle}
          onLogicSharedToggle={logicSharedToggle}
          onPublishCampaign={publishCampaign}
          onExportList={exportList}
          onExportAuditFile={exportAuditFile}
          onDetailClick={detailClick}
        />
      </section>
      <section className="mt-6">
        <ExportFileSummary
          totalRecords={calculatedMatchSummary?.totalRecords}
          unmatchedImportedFile={calculatedMatchSummary?.unmatchedImportedFile}
          unmatchedFiltered={calculatedMatchSummary?.unmatchedFiltered}
        />
      </section>
      <section className="mt-6 p-0 col-6">
        <header>
          <h4>Campaign Logic</h4>
        </header>
        <article className="">
          <p>Here you can see all filters applied to generate your campaign.</p>
        </article>
      </section>

      {campaign?.logic ? (
        <section>
          <QueryFilterBuilder
            valueQuery={campaign.logic}
            filters={filters}
            readOnly
          />
        </section>
      ) : null}

      <section className="mt-2">
        <QueryFilterPreview query={computeQueryLogic} />
      </section>

      <section className="mt-3">
        <CsvDataTable
          loading={previewDataLoading}
          config={previewTableConfig}
          columns={columns}
          records={records}
          totalRecordCount={totalRecordCount}
          height="300px"
        />
      </section>
    </section>
  );
}

export { CampaignDetails };
